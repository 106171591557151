import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import pdf from '../node_modules/vue3-pdf/src/pdfjsWrapper'




// Styles
import "bootstrap"
import "bootstrap/dist/css/bootstrap.min.css"
import './assets/main.css'


//devextrem//
// import 'devextreme/dist/css/dx.common.css';
// import 'devextreme/dist/css/dx.light.css';


createApp(App).use(store).use(pdf).use(router).mount('#app')
