<template>
  <div class="home">
    <div class="container mt-5">
      <div class="row">
        <div class="col-sm-4 mx-auto border-rounded p-5 text-left">

          <div class="card-body">
            
            <center>
              <img src="@/assets/logo.png" width="120">
            </center>
            <br>
            <br>
            <h2>Login</h2>
            <div id="message" class="text-danger fw-bold">{{message}}</div>
            <form @submit.prevent="login">
              <label>Email</label>
              <input type="email" v-model="email"  class="form-control" required>
              <label>Password</label>
              <input :type="showPassword == true ?  'text' : 'password'" v-model="password" class="form-control" required> 
              <hr>
              <div class="form-group form-check">
                <input type="checkbox" class="form-check-input" id="show-password" v-model="showPassword" @click="toggle">
                <label class="form-check-label" for="show-password">Show password</label>
              </div>


              <input type="submit" value="Login" class="btn btn-warning btn-lg fw-semibold text-bold btn-lg">
            </form>
            
            <br>        
            <br>        
            <br>
            <center><router-link to="forgotPswd">Forgot password?</router-link></center>        
            <br>        
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'

export default {
  name: 'HomeView',
  components: {
    Header
  },
  data(){
    return{
      email:'',
      password:'',
      message:'',
      showPassword:false,
    }
  },
  methods:{
    async login(){
        let data = {
                      email:this.email,
                      password:this.password,
                    }
        console.log(data)
        fetch(process.env.VUE_APP_AUTH_SERVER+'/login', {
            method: "POST",
            body: JSON.stringify(data),
            headers: {"Content-type": "application/json; charset=UTF-8"}
        })
        .then(response => response.json()) 
        .then(data => {
            console.log(data.message)
            const message = data.message
            if(data.type == 'success'){
                document.getElementById('message').innerHTML = `<p class="btn btn-success">${message}</p>`
                localStorage.setItem('jwt',data.token)
                localStorage.setItem('user',data.user)
                console.log(JSON.parse(data.user))
                const user = JSON.parse(data.user)
                if(user.accountType == 'accountAdmin'){
                  window.location.href = '/settings';
                }else{
                  window.location.href = '/dash';
                }
            }else{
                this.message = data.message
                this.button = '<input type="submit" value="save document" class="btn btn-warning btn-lg fw-semibold">'
            }                    
        })
        .catch(err => console.log(err))
    },


    // async login(){
    //     let data = {
    //                   email:this.email,
    //                   password:this.password,
    //                 }
    //     console.log(data)
    //     fetch(process.env.VUE_APP_AUTH_SERVER+'/logintest', {
    //         method: "POST",
    //         body: JSON.stringify(data),
    //         headers: {"Content-type": "application/json; charset=UTF-8"}
    //     })
    //     .then(response => response.json()) 
    //     .then(data => {
    //         console.log(data.message)
    //         const message = data.message
    //         if(data.type == 'success'){

    //           document.getElementById('message').innerHTML = `<p class="btn btn-success">${message}</p>`
             
    //             window.location.href = '/confirmToken';

              
             
    //               // window.location.href = '/confirmToken';
                
    //         }else{
    //             this.message = data.message
    //             this.button = '<input type="submit" value="save document" class="btn btn-warning btn-lg fw-semibold">'
    //         }                    
    //     })
    //     .catch(err => console.log(err))
    // },
    toggle() {
      this.showPassword = !this.showPassword;
    }



  },
  mounted(){
    window.onload = function() {
      if(!window.location.hash) {
          window.location = window.location + '#loaded';
          // window.location.reload();
      }
    }
  }
}
</script>
