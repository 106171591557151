<template>
  <div>
    <nav class="navbar navbar-expand-lg navbar-dark bg-dark p-3 ">
    <div class="container-fluid">        
          <router-link class="text-dark fw-bold col mx-auto" aria-current="page" to="/dash">
            <img src="../assets/joyful.png" style="width:30px; float:center;"> 
            <span class="fw-bold text-white p-3 mt-6" style="font-size:larger">
              Joyful Systems
            </span>
          </router-link>
    </div>
    </nav>
    
    <div v-if="istatus == 'offline'" class="alert alert-success alert-dismissible fade show mt-5z" role="alert">
      You are working offline
      <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
    </div>
 
  </div>
</template>

<script>
export default {

  data(){
    return{
      status: this.status,
      istatus: this.istatus,
      modList: [],
      root:process.env.VUE_APP_AUTH_SERVER,
      name:this.name,
      role:this.role,
      dept:this.dept,
    }
  },
  methods:{
      userInfo() {
        const info = localStorage.getItem("user");
        this.user = JSON.parse(info);
        console.log(typeof this.user);
        
        if(this.user != null){
          this.name = this.user.name
        this.role = this.user.role.join(", ")
        this.dept = this.user.dept.join(", ")
        console.log(this.role)
          this.status = "session"
          console.log(this.status)
          // this.org()
        }else{
          this.status = "noSession"
          console.log(this.status)
        } 
      },
      
      
  },
  mounted(){
    this.userInfo()
    
    if (navigator.onLine) {
      console.log('online');
      this.istatus = "online"
    } else {
      console.log('offline');
      this.istatus = "offline"
    }
  }
}
</script>

<style>

</style>