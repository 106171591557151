import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/dash',
    name: 'IconsDash',
    component: () => import('../views/iconsDash.vue'),
  },

  {
    path: '/confirmToken',
    name: 'ConfirmToken',
    component: () => import('../views/ConfirmToken.vue'),
  },
  {
    path: '/registerUser',
    name: 'RegisterUser',
    component: () => import('../views/adminSignup.vue'),
  },
  {
    path: '/users',
    name: 'Users',
    component: () => import('../views/Users.vue'),
  },
  {
    path: '/editUser/:id',
    name: 'editUser',
    component: () => import('../views/editUser.vue'),
  },
  {
    path: '/editBioData/:id/:gId',
    name: 'editBioData',
    component: () => import('../views/editBioData.vue'),
  },

  {
    path: '/EditMember/:memberId/:groupId',
    name: 'EditMember',
    component: () => import('../views/group/EditMember.vue'),
  },

  {
    path: '/CreateGroupOpeningBalance/:gId',
    name: 'CreateGroupOpeningBalance',
    component: () => import('../views/group/CreateGroupOpeningBalance.vue'),
  },

  {
    path: '/editBiz/:memberId/:groupId',
    name: 'editBiz',
    component: () => import('../views/group/editBiz.vue'),
  },
  {
    path: '/GroupReport/:gId/:mid',
    name: 'GroupReport',
    component: () => import('../views/group/GroupReports.vue'),
  },

  {
    path: '/EditEconomicActivity/:id',
    name: 'EditEconomicActivity',
    component: () => import('../views/group/EditEconomicActivity.vue'),
  },
  {
    path: '/editPassword/:id',
    name: 'editPassword',
    component: () => import('../views/editPassword.vue'),
  },

  {
    path: '/editPasswordUsers/:id',
    name: 'editPasswordUsers',
    component: () => import('../views/editPasswordUsers.vue'),
  },

  {
    path: '/GroupMontly/:gId',
    name: 'GroupMontly',
    component: () => import('../views/loans/GroupMontly.vue'),
  },

  {
    path: '/ApprovedMeetings/:gId',
    name: 'ApprovedMeetings',
    component: () => import('../views/tb/GroupMontly/ApprovedMeetings.vue'),
  },
  {
    path: '/chart',
    name: 'Chart',
    component: () => import('../views/chart.vue'),
  },

  {
    path: '/tbLink',
    name: 'tbLink',
    component: () => import('../views/TbLink.vue'),
  },
  {
    path: '/members',
    name: 'Members',
    component: () => import('../views/members.vue'),
  },

  {
    path: '/newGroup',
    name: 'newGroup',
    component: () => import('../views/group/NewGroup.vue'),
  },
  {
    path: '/addMember/:id',
    name: 'addMember',
    component: () => import('../views/group/AddMember.vue'),
  },

  {
    path: '/addProduct/:gId',
    name: 'addProduct',
    component: () => import('../views/group/AddProduct.vue'),
  },

  {
    path: '/SavingsCF/:gId',
    name: 'SavingsCF',
    component: () => import('../views/Dividence/SavingsCF.vue'),
  },

  {
    path: '/SavingsForm/:id/:gId',
    name: 'SavingsForm',
    component: () => import('../views/Dividence/SavingsForm.vue'),
  },

  {
    path: '/DividentSavingsReport/:gId',
    name: 'DividentSavingsReport',
    component: () => import('../views/Dividence/SavingsReport.vue'),
  },
  {
    path: '/DividentBoosterReport/:gId',
    name: 'DividentBoosterReport',
    component: () => import('../views/Dividence/DividentBoosterReport.vue'),
  },

  {
    path: '/BoosterForm/:id/:gId',
    name: 'BoosterForm',
    component: () => import('../views/Dividence/BoosterForm.vue'),
  },
  {
    path: '/BoosterCF/:gId',
    name: 'BoosterCF',
    component: () => import('../views/Dividence/BoosterCF.vue'),
  },

  {
    path: '/NovemberCF/:gId',
    name: 'NovemberCF',
    component: () => import('../views/Dividence/NovemberCF.vue'),
  },

  {
    path: '/NovemebercfForm/:id/:gId',
    name: 'NovemebercfForm',
    component: () => import('../views/Dividence/NovemebercfForm.vue'),
  },
  {
    path: '/GroupMembersReport',
    name: 'GroupMembersReport',
    component: () => import('../views/group/GroupMembersReport.vue'),
  },

  {
    path: '/OpeningBalance/:id',
    name: 'OpeningBalance',
    component: () => import('../views/group/OpeningBalance.vue'),
  },
  {
    path: '/divcalc',
    name: 'Divcalc',
    component: () => import('../views/divcalc.vue'),
  },
  {
    path: '/groups',
    name: 'Groups',
    component: () => import('../views/groups.vue'),
  },

  {
    path: '/Orders',
    name: 'Orders',
    component: () => import('../views/products/Orders.vue'),
  },

  {
    path: '/ConfirmOrder/:id',
    name: 'ConfirmOrder',
    component: () => import('../views/products/ConfirmOrder.vue'),
  },

  // {
  //   path: '/Payments/:id',
  //   name: 'Payments',
  //   component: () => import('../views/products/Payments.vue')
  // },
  {
    path: '/tbreports',
    name: 'TBReports',
    component: () => import('../views/tbreports.vue'),
  },

  // Table Banking
  {
    path: '/tb',
    name: 'tb',
    component: () => import('../views/tb/tb.vue'),
  },
  {
    path: '/meetings',
    name: 'meetings',
    component: () => import('../views/tb/meetings.vue'),
  },
  {
    path: '/meeting',
    name: 'meeting',
    component: () => import('../views/tb/meeting.vue'),
  },
  {
    path: '/CreateMeeting',
    name: 'CreateMeeting',
    component: () => import('../views/tb/CreateMeeting.vue'),
  },
  {
    path: '/openingBalance',
    name: 'openingBalance',
    component: () => import('../views/tb/openingBalance.vue'),
  },

  {
    path: '/TBGroups',
    name: 'TBGroups',
    component: () => import('../views/tb/TBGroups.vue'),
  },

  {
    path: '/TBOpeningBalance',
    name: 'TBOpeningBalance',
    component: () => import('../views/tb/TBOpeningBalance.vue'),
  },

  {
    path: '/TBSummary',
    name: 'TBSummary',
    component: () => import('../views/tb/TBSummary.vue'),
  },
  {
    path: '/TBMeeting',
    name: 'TBMeeting',
    component: () => import('../views/tb/TBMeeting.vue'),
  },
  {
    path: '/AwaitingApproval',
    name: 'AwaitingApproval',
    component: () => import('../views/tb/AwaitingApproval.vue'),
  },

  {
    path: '/Draft',
    name: 'Draft',
    component: () => import('../views/tb/Draft.vue'),
  },
  {
    path: '/ApprovedMeeting',
    name: 'ApprovedMeeting',
    component: () => import('../views/tb/ApprovedMeeting.vue'),
  },

  {
    path: '/TBAllMeetings',
    name: 'TBAllMeetings',
    component: () => import('../views/tb/TBAllMeetings.vue'),
  },
  {
    path: '/StartMeeting/:gId',
    name: 'StartMeeting',
    component: () => import('../views/group/StartMeeting.vue'),
  },

  // {
  //   path: '/groupOpeningBalances/:id',
  //   name: 'groupOpeningBalances',
  //   component: () => import('../views/tb/groupOpeningBalances.vue')
  // },
  {
    path: '/TBGroupMeeting/:id',
    name: 'TBGroupMeeting',
    component: () => import('../views/tb/TBGroupsMeeting.vue'),
  },
  {
    path: '/TBAttendance/:mid:/:gId',
    name: 'TBAttendance',
    component: () => import('../views/tb/TBAttendance.vue'),
  },
  {
    path: '/TBAttendanceReport/:mid:/:gId',
    name: 'TBAttendanceReport',
    component: () => import('../views/tb/TBAttendanceReport.vue'),
  },
  {
    path: '/TBRegisterNewMember/:mid:/:gId',
    name: 'TBRegisterNewMember',
    component: () => import('../views/group/TBRegisterNewMember.vue'),
  },

  {
    path: '/TBJoyfulLoanRepayment/:mid:/:gId',
    name: 'TBJoyfulLoanRepayment',
    component: () => import('../views/tb/TBJoyfulLoanRepayment.vue'),
  },

  {
    path: '/TBDestinyLoans/:mid:/:gId',
    name: 'TBDestinyLoans',
    component: () => import('../views/tb/TBDestinyLoans.vue'),
  },

  {
    path: '/TBJoyfulLoanTransactionFee/:mid:/:gId',
    name: 'TBJoyfulLoanTransactionFee',
    component: () => import('../views/tb/TBJoyfulLoanTransactionFee.vue'),
  },

  {
    path: '/BonusCF/:mid:/:gId',
    name: 'BonusCF',
    component: () => import('../views/tb/BonusCF.vue'),
  },

  {
    path: '/TBEditBiz/:id/:mid:/:gId',
    name: 'TBEditBiz',
    component: () => import('../views/group/TBEditBiz.vue'),
  },
  {
    path: '/TBCashIn/:mid:/:gId',
    name: 'TBCashIn',
    component: () => import('../views/tb/TBCashIn.vue'),
  },

  {
    path: '/SavingsWithdrawal/:mid:/:gId',
    name: 'SavingsWithdrawal',
    component: () => import('../views/tb/SavingsWithdrawal.vue'),
  },
  {
    path: '/TBCheckBook/:mid:/:gId',
    name: 'TBCheckBook',
    component: () => import('../views/tb/TBCheckBook.vue'),
  },
  {
    path: '/TBBooster/:mid:/:gId',
    name: 'TBBooster',
    component: () => import('../views/tb/TBBooster.vue'),
  },
  {
    path: '/TBBoosterReport/:mid:/:gId',
    name: 'TBBoosterReport',
    component: () => import('../views/tb/TBBoosterReport.vue'),
  },
  {
    path: '/BankingsIn/:mid:/:gId',
    name: 'BankingsIn',
    component: () => import('../views/tb/BankingsIn.vue'),
  },
  {
    path: '/BankingsInReport/:mid:/:gId',
    name: 'BankingsInReport',
    component: () => import('../views/tb/BankingsInReport.vue'),
  },

  {
    path: '/BankingsOutReport/:mid:/:gId',
    name: 'BankingsOutReport',
    component: () => import('../views/tb/BankingsOutReport.vue'),
  },
  {
    path: '/BankingsOut/:mid:/:gId',
    name: 'BankingsOut',
    component: () => import('../views/tb/BankingsOut.vue'),
  },
  {
    path: '/TBFines/:mid:/:gId',
    name: 'TBFines',
    component: () => import('../views/tb/TBFines.vue'),
  },
  {
    path: '/TBCashInReport/:mid:/:gId',
    name: 'TBCashInReport',
    component: () => import('../views/tb/TBCashInReport.vue'),
  },

  {
    path: '/TBLTLReport/:mid:/:gId',
    name: 'TBLTLReport',
    component: () => import('../views/tb/TBLTLReport.vue'),
  },
  {
    path: '/LTLCashInReport/:mid:/:gId',
    name: 'LTLCashInReport',
    component: () => import('../views/tb/LTLCashInReport.vue'),
  },

  {
    path: '/ProductRegistration/:mid:/:gId',
    name: 'ProductRegistration',
    component: () => import('../views/tb/ProductRegistration.vue'),
  },

  {
    path: '/TBSTLReport/:mid:/:gId',
    name: 'TBSTLReport',
    component: () => import('../views/tb/TBSTLReport.vue'),
  },

  {
    path: '/EndMeeting/:mid:/:gId',
    name: 'EndMeeting',
    component: () => import('../views/tb/EndMeeting.vue'),
  },

  {
    path: '/TBCashOut',
    name: 'TBCashOut',
    component: () => import('../views/tb/TBCashout.vue'),
  },
  {
    path: '/LTLCashOut/:gId/:mid',
    name: 'LTLCashOut',
    component: () => import('../views/tb/LTLCashout.vue'),
  },
  {
    path: '/STLReport/:gId/:mid',
    name: 'STLReport',
    component: () => import('../views/tb/STLReport.vue'),
  },
  {
    path: '/LTLReport/:gId/:mid',
    name: 'LTLReport',
    component: () => import('../views/tb/LTLReport.vue'),
  },
  // {
  //   path: '/LTLCashOutReport/:gId/:mid',
  //   name: 'LTLCashOutReport',
  //   component: () => import('../views/tb/LTLCashOutReport.vue')
  // },
  {
    path: '/STLCashOutReport/:gId/:mid',
    name: 'STLCashOutReport',
    component: () => import('../views/tb/STLCashOutReport.vue'),
  },

  {
    path: '/BoosterReport/:gId/:mid',
    name: 'BoosterReport',
    component: () => import('../views/tb/BoosterReport.vue'),
  },
  {
    path: '/SavingsReport/:gId/:mid',
    name: 'SavingsReport',
    component: () => import('../views/tb/SavingsReport.vue'),
  },
  {
    path: '/STLCashOut/:gId/:mid',
    name: 'STLCashOut',
    component: () => import('../views/tb/STLCashout.vue'),
  },

  {
    path: '/WelareClaim/:gId/:mid',
    name: 'WelareClaim',
    component: () => import('../views/tb/WelareClaim.vue'),
  },
  {
    path: '/TBReport',
    name: 'TBReport',
    component: () => import('../views/tb/TBReport.vue'),
  },
  {
    path: '/GMReport',
    name: 'GMReport',
    component: () => import('../views/tb/GMReport.vue'),
  },
  {
    path: '/TBEndMeeting',
    name: 'TBEndMeeting',
    component: () => import('../views/tb/TBEndMeeting.vue'),
  },
  {
    path: '/openingBalanceNew',
    name: 'openingBalanceNew',
    component: () => import('../views/tb/openingBalanceNew.vue'),
  },
  {
    path: '/TBOpeningBalanceNew',
    name: 'TBOpeningBalanceNew',
    component: () => import('../views/tb/TBOPeningBalanceNew.vue'),
  },

  {
    path: '/TBOpeningBalanceForm/:id/:gId',
    name: 'TBOpeningBalanceForm',
    component: () => import('../views/tb/TBOpeningBalanceForm.vue'),
  },
  {
    path: '/openingBalanceForm/:id/:gId',
    name: 'openingBalanceForm',
    component: () => import('../views/tb/openingBalanceForm.vue'),
  },
  {
    path: '/groupOpeningBalances/:id',
    name: 'groupOpeningBalances',
    component: () => import('../views/tb/groupOpeningBalances.vue'),
  },
  {
    path: '/TBGroupOpeningBalances/:id',
    name: 'TBGroupOpeningBalances',
    component: () => import('../views/tb/TBGroupOpeningBalances.vue'),
  },

  {
    path: '/TBGroupSettings/:id',
    name: 'TBGroupSettings',
    component: () => import('../views/tb/TBGroupSettings.vue'),
  },

  {
    path: '/TBGroupOpeningBalancesCreate/:gId',
    name: 'TBGroupOpeningBalancesCreate',
    component: () => import('../views/tb/TBGroupOpeningBalancesCreate.vue'),
  },

  {
    path: '/TBGroupOpeningBalancesReport/:gId/:mid',
    name: 'TBGroupOpeningBalancesReport',
    component: () => import('../views/tb/TBGroupOpeningBalancesReport.vue'),
  },
  {
    path: '/groupMeeting/:gId/:mid',
    name: 'groupMeeting',
    component: () => import('../views/tb/meeting.vue'),
  },

  {
    path: '/GroupMeetings/:gId',
    name: 'GroupMeetings',
    component: () => import('../views/group/GroupMeetings.vue'),
  },
  {
    path: '/cashin/:id/:mid/:gId',
    name: 'cashin',
    component: () => import('../views/tb/cashin.vue'),
  },
  {
    path: '/cashout/:id/:mid/:gId',
    name: 'cashout',
    component: () => import('../views/tb/cashout.vue'),
  },
  {
    path: '/banking/:id/:mid/:admin',
    name: 'banking',
    component: () => import('../views/tb/banking.vue'),
  },
  {
    path: '/groupRegistration',
    name: 'groupRegistration',
    component: () => import('../views/group/groupRegistration.vue'),
  },

  {
    path: '/transfer',
    name: 'transfer',
    component: () => import('../views/Transfer.vue'),
  },
  {
    path: '/groupBizRegistration',
    name: 'groupBizRegistration',
    component: () => import('../views/groupBizRegistration.vue'),
  },
  {
    path: '/group/:gId',
    name: 'Group',
    component: () => import('../views/Dividence/group.vue'),
  },

  {
    path: '/OpeningBalance/:gId',
    name: 'OpeningBalance',
    component: () => import('../views/group/OpeningBalance.vue'),
  },
  {
    path: '/groupReports',
    name: 'GroupReports',
    component: () => import('../views/groupReports.vue'),
  },
  {
    path: '/countyReports/:county',
    name: 'CountyReports',
    component: () => import('../views/countyReports.vue'),
  },
  {
    path: '/tbcountyReports/:county',
    name: 'TBCountyReports',
    component: () => import('../views/TBCountyReports.vue'),
  },
  {
    path: '/eeoReports/:id',
    name: 'eeoReports',
    component: () => import('../views/eeoReports.vue'),
  },

  {
    path: '/TbEeoReports/:id',
    name: 'TbEeoReports',
    component: () => import('../views/TbEeoReports.vue'),
  },
  {
    path: '/theReports',
    name: 'TheReports',
    component: () => import('../views/theReports.vue'),
  },
  {
    path: '/eeogroupReports/:id',
    name: 'EeoGroupReports',
    component: () => import('../views/EeoGroupReport.vue'),
  },
  {
    path: '/groupReportForm',
    name: 'groupReportForm',
    component: () => import('../views/groupReportForm.vue'),
  },
  {
    path: '/memebers',
    name: 'memebers',
    component: () => import('../views/MembersReport.vue'),
  },
  {
    path: '/groupReportFormEdit/:id',
    name: 'groupReportFormEdit',
    component: () => import('../views/groupReportFormEdit.vue'),
  },
  {
    path: '/groupReportFormDelete/:id/:md',
    name: 'groupReportFormDelete',
    component: () => import('../views/groupReportFormDelete.vue'),
  },
  {
    path: '/groupDelete/:gId',
    name: 'groupDelete',
    component: () => import('../views/groupDelete.vue'),
  },
  {
    path: '/groupActivate/:gId/:systemCode',
    name: 'groupActivate',
    component: () => import('../views/ActivateGroup.vue'),
  },
  {
    path: '/memberDelete/:mId/:memberName/:gId',
    name: 'memberDelete',
    component: () => import('../views/memberDelete.vue'),
  },
  {
    path: '/bizDelete/:mId/:bizId',
    name: 'bizDelete',
    component: () => import('../views/bizDelete.vue'),
  },
  {
    path: '/groupData/:gId',
    name: 'groupData',
    component: () => import('../views/groupData.vue'),
  },
  {
    path: '/groupMembers/:gId',
    name: 'groupMembers',
    component: () => import('../views/group/groupMembersBioData.vue'),
  },

  {
    path: '/GroupOfficials/:gId',
    name: 'GroupOfficials',
    component: () => import('../views/group/GroupOfficials.vue'),
  },
  {
    path: '/ChangeGroupOfficials/:gId',
    name: 'ChangeGroupOfficials',
    component: () => import('../views/group/ChangeGroupOfficials.vue'),
  },

  {
    path: '/Officials/:gId',
    name: 'Officials',
    component: () => import('../views/group/Officials.vue'),
  },

  {
    path: '/TransferEEO/:gId',
    name: 'TransferEEO',
    component: () => import('../views/group/TransferEEO.vue'),
  },

  {
    path: '/InterTransferEEO/:gId',
    name: 'InterTransferEEO',
    component: () => import('../views/group/InterTransferEEO.vue'),
  },

  {
    path: '/Members/:gId',
    name: 'Members',
    component: () => import('../views/group/Members.vue'),
  },
  {
    path: '/GroupManagement/:gId',
    name: 'GroupManagement',
    component: () => import('../views/group/GroupManagement.vue'),
  },
  {
    path: '/MembersDetails/:gId',
    name: 'MembersDetails',
    component: () => import('../views/group/MembersDetails.vue'),
  },
  {
    path: '/MemberDetails/:mId/:gId',
    name: 'MemberDetails',
    component: () => import('../views/group/MemberDetails.vue'),
  },

  {
    path: '/Others/:gId',
    name: 'Others',
    component: () => import('../views/group/Others.vue'),
  },

  {
    path: '/ThankGiving/:gId',
    name: 'ThankGiving',
    component: () => import('../views/group/Thanks.vue'),
  },

  {
    path: '/ThankGivingApplication/:gId',
    name: 'ThankGivingApplication',
    component: () => import('../views/group/ThanksE.vue'),
  },

  {
    path: '/ThankGivingAttendace',
    name: 'ThankGivingAttendace',
    component: () => import('../views/group/A.vue'),
  },

  {
    path: '/Register/:gId',
    name: 'Register',
    component: () => import('../views/group/Register.vue'),
  },

  {
    path: '/TableBanking/:gId',
    name: 'TableBanking',
    component: () => import('../views/group/T.vue'),
  },

  {
    path: '/groupMembersBiz/:gId',
    name: 'groupMembersBiz',
    component: () => import('../views/groupMembersBiz.vue'),
  },
  {
    path: '/groupRecords/:gId',
    name: 'groupRecords',
    component: () => import('../views/groupRecords.vue'),
  },
  {
    path: '/dividendForm/:gId',
    name: 'dividendForm',
    component: () => import('../views/dividendForm.vue'),
  },
  {
    path: '/member/:mId',
    name: 'Member',
    component: () => import('../views/member.vue'),
  },
  {
    path: '/memberReports/:mId',
    name: 'memberReports',
    component: () => import('../views/memberReports.vue'),
  },
  {
    path: '/upload',
    name: 'Upload',
    component: () => import('../views/upload.vue'),
  },
  {
    path: '/uploadDestiny',
    name: 'UploadDestiny',
    component: () => import('../views/uploadDestinyRecords.vue'),
  },
  {
    path: '/dividendForms',
    name: 'dividendForms',
    component: () => import('../views/dividendForms.vue'),
  },
  {
    path: '/dividendReports',
    name: 'dividendReports',
    component: () => import('../views/dividendReports.vue'),
  },
  {
    path: '/nationalDividendReports',
    name: 'nationalDividendReports',
    component: () => import('../views/nationalDividendReports.vue'),
  },
  {
    path: '/reports',
    name: 'Reports',
    component: () => import('../views/reports.vue'),
  },
  {
    path: '/kuzamkopo',
    name: 'kuzamkopo',
    component: () => import('../views/kuzamkopo.vue'),
  },
  {
    path: '/loanSumary',
    name: 'LoanSummary',
    component: () => import('../views/LoanSummary.vue'),
  },
  {
    path: '/montlyRemitance',
    name: 'montlyRemitance',
    component: () => import('../views/MontlyRemitanceReport'),
  },
  {
    path: '/consolidatedReport',
    name: 'consolidatedReport',
    component: () => import('../views/ConsolidatedReports.vue'),
  },
  {
    path: '/historicrecords',
    name: 'historicrecords',
    component: () => import('../views/historicrecords.vue'),
  },

  {
    path: '/historicalLoans',
    name: 'historialLoans',
    component: () => import('../views/HistoricalLoans.vue'),
  },
  {
    path: '/loans',
    name: 'loans',
    component: () => import('../views/loans.vue'),
  },
  {
    path: '/allLoans',
    name: 'AllLOans',
    component: () => import('../views/AllLoans.vue'),
  },
  {
    path: '/GroupLoansSummary',
    name: 'GroupLoansSummary',
    component: () => import('../views/GroupLoansSummary.vue'),
  },
  {
    path: '/loansIncome',
    name: 'loansIncome',
    component: () => import('../views/loansIncome.vue'),
  },
  {
    path: '/editLoanRepayment/:id',
    name: 'editLoanRepayment',
    component: () => import('../views/EditLoanRepayment.vue'),
  },
  {
    path: '/unprocessed',
    name: 'unprocessed',
    component: () => import('../views/Unprocessed.vue'),
  },

  {
    path: '/loanreport',
    name: 'loanreport',
    component: () => import('../views/loanReport.vue'),
  },

  {
    path: '/underprocessing',
    name: 'underprocessing',
    component: () => import('../views/UnderProcessing.vue'),
  },
  {
    path: '/active',
    name: 'active',
    component: () => import('../views/ActiveLoans.vue'),
  },
  {
    path: '/clearedloans',
    name: 'clearedloans',
    component: () => import('../views/clearedloans.vue'),
  },
  {
    path: '/loanProducts',
    name: 'loanProducts',
    component: () => import('../views/loanProducts.vue'),
  },

  {
    path: '/joyfullProducts',
    name: 'joyfullProducts',
    component: () => import('../views/JoyfulProducts.vue'),
  },
  {
    path: '/editLoanProducts/:id',
    name: 'editLoanProducts',
    component: () => import('../views/editLoanProduct.vue'),
  },
  {
    path: '/editJoyfulProducts/:id',
    name: 'editJoyfulProducts',
    component: () => import('../views/editJoyfulProducts.vue'),
  },
  {
    path: '/editPaymentReport/:id',
    name: 'editPaymentReport',
    component: () => import('../views/EditPaymentReport.vue'),
  },
  {
    path: '/clientType',
    name: 'clientType',
    component: () => import('../views/clientType.vue'),
  },
  {
    path: '/ledgerType',
    name: 'ledgerType',
    component: () => import('../views/ledgerType.vue'),
  },
  {
    path: '/ledgers',
    name: 'ledgers',
    component: () => import('../views/ledgers.vue'),
  },
  {
    path: '/setup',
    name: 'setup',
    component: () => import('../views/setup.vue'),
  },
  {
    path: '/loansCreditDash',
    name: 'loansCreditDash',
    component: () => import('../views/loansCreditDash.vue'),
  },
  {
    path: '/appraisalLoans',
    name: 'appraisalLoans',
    component: () => import('../views/appraisalLoans.vue'),
  },
  {
    path: '/approvalLoans',
    name: 'approvalLoans',
    component: () => import('../views/approvalLoans.vue'),
  },
  {
    path: '/disbursmentLoans',
    name: 'disbursmentLoans',
    component: () => import('../views/disbursmentLoans.vue'),
  },
  {
    path: '/disbursmentForm/:id',
    name: 'disbursmentForm',
    component: () => import('../views/loans/disbursementForm.vue'),
  },
  {
    path: '/rejectedLoans',
    name: 'rejectedLoans',
    component: () => import('../views/rejectedLoans.vue'),
  },

  {
    path: '/eeorejectedLoans',
    name: 'eeorejectedLoans',
    component: () => import('../views/EEORejectedLoans.vue'),
  },

  {
    path: '/ rejectedLoansDelete/:id',
    name: 'rejectedLoansDelete',
    component: () => import('../views/RejectedLoadelete.vue'),
  },
  {
    path: '/loanBook',
    name: 'loanBook',
    component: () => import('../views/loanBook.vue'),
  },
  {
    path: '/paymentApproval',
    name: 'paymentApproval',
    component: () => import('../views/paymentApproval.vue'),
  },
  {
    path: '/destiny',
    name: 'destiny',
    component: () => import('../views/destiny.vue'),
  },
  {
    path: '/destinyLoans',
    name: 'destinyLoans',
    component: () => import('../views/destinyLoans.vue'),
  },
  {
    path: '/destinyLoan/:id',
    name: 'destinyLoan',
    component: () => import('../views/destinyLoan.vue'),
  },
  {
    path: '/destinyStatement/:id',
    name: 'destinyStatement',
    component: () => import('../views/destinyStatement.vue'),
  },
  {
    path: '/loan/:id',
    name: 'loan',
    component: () => import('../views/loan.vue'),
  },
  {
    path: '/loansHistory/:id',
    name: 'loansHistory',
    component: () => import('../views/LoansHistory.vue'),
  },
  {
    path: '/payments/:id',
    name: 'payments',
    component: () => import('../views/payments.vue'),
  },
  {
    path: '/paymentsReport',
    name: 'paymentsReport',
    component: () => import('../views/PaymentsReport.vue'),
  },
  {
    path: '/trainingReport',
    name: 'trainingReport',
    component: () => import('../views/TrainingReport.vue'),
  },

  {
    path: '/CreditEditLoan/:id/:gId',
    name: 'CreditEditLoan',
    component: () => import('../views/CreditEditLoan.vue'),
  },

  {
    path: '/paymentsDelete/:id',
    name: 'paymentsDelete',
    component: () => import('../views/PaymentsDelete.vue'),
  },
  {
    path: '/recordedpaymentsDelete/:id',
    name: 'recordedpaymentsDelete',
    component: () => import('../views/RecordedPaymentsDelete.vue'),
  },
  {
    path: '/schedule/:id',
    name: 'schedule',
    component: () => import('../views/schedule.vue'),
  },
  {
    path: '/statement/:id',
    name: 'statement',
    component: () => import('../views/statement.vue'),
  },
  {
    path: '/editloan/:id',
    name: 'editloan',
    component: () => import('../views/editloan.vue'),
  },
  {
    path: '/FileReUpload/:id',
    name: 'FileReUpload',
    component: () => import('../views/FileReUpload.vue'),
  },
  {
    path: '/test',
    name: 'test',
    component: () => import('../views/test.vue'),
  },
  {
    path: '/logout',
    name: 'Logout',
    component: () => import('../views/Logout.vue'),
    meta: {
      requiresAuth: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
